import React from 'react'
import { graphql } from 'gatsby'
import KnowledgeCenterPage from './knowledge-center-page'

const KnowledgeCenterTagTemplate = props => {
  return <KnowledgeCenterPage {...props} />
}

export const pageQuery = graphql`
  query knowledgeCenterTagTemplate($tag: String!) {
    allMarkdownRemark(
      filter: { fields: { type: { eq: "KnowledgeCenter" } }, frontmatter: { tags: { eq: $tag } } }
      sort: { fields: [frontmatter___feature], order: DESC }
    ) {
      nodes {
        frontmatter {
          feature
        }
        fields {
          letterGroup
        }
      }
    }
  }
`

export default KnowledgeCenterTagTemplate
