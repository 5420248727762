import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import Content from 'components/content'
import { Helmet } from 'react-helmet'
import slugify from 'utils/slugify'
import sortObjectKeys from 'utils/sort-object-keys'
import { Link } from 'gatsby'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const KnowledgeCenterPageTemplate = props => {
  const { breadcrumbs, count, data, description, meta, title } = getInitialProps(props)
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <StyledContent>
        <Title>
          {title} {count && <>({count})</>}
        </Title>
        <Description>{description}</Description>
        {Object.keys(data).map(letter => (
          <div key={`Group_${letter}`}>
            <GroupHeader>{letter.toUpperCase()}</GroupHeader>
            <List>
              {data[letter].map(post => (
                <ListItem key={post}>
                  <StyledLink to={`/knowledge-center${slugify(post)}`}>{post}</StyledLink>
                </ListItem>
              ))}
            </List>
          </div>
        ))}
      </StyledContent>
    </Layout>
  )
}

const StyledContent = styled(Content)`
  @media (min-width: 800px) {
    max-width: 800px;
  }
`

const Title = styled.h1`
  font-size: 2em;
`

const Description = styled.p``

const GroupHeader = styled.h2`
  border-bottom: 1px solid #d7d7d7;
  margin: 30px 0 10px;
  padding: 0 0 5px 0;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  display: inline-block;
  list-style: none;
  margin: 10px 15px;
  padding: 0;
`

const StyledLink = styled(Link)`
  color: #377539;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const getInitialProps = ({ data: { allMarkdownRemark }, pageContext: { count, tag } }) => {
  const meta = {}

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Knowledge Center',
      link: '/knowledge-center/',
    },
  ]

  let title
  let description = ''

  if (tag) {
    title = `Knowledge Center - ${tag}`
    meta.title = `Knowledge Center - ${tag} | Hutson Inc`
    meta.description = `In-depth articles and insights related to ${tag}. Check out Hutson’s knowledge center to learn about features on John Deere and STIHL equipment.`
    breadcrumbs.push(
      {
        name: 'Tags',
        link: '/knowledge-center/tags/',
      },
      {
        name: tag,
        link: `/knowledge-center/tags${slugify(tag)}`,
      }
    )
  } else {
    title = 'Knowledge Center'
    description =
      'Learn more about features on John Deere and STIHL equipment with our in-depth articles and insights.'
    meta.title = 'Knowledge Center | Hutson Inc'
    meta.description =
      'Want to learn more about features on John Deere and STIHL equipment? Check out Hutson’s knowledge center for in-depth articles and insights.'
  }

  const letterGroups = allMarkdownRemark.nodes.reduce((acc, article) => {
    const {
      fields: { letterGroup },
      frontmatter: { feature },
    } = article

    if (acc[letterGroup]) {
      acc[letterGroup].push(feature)
    } else {
      acc[letterGroup] = [feature]
    }

    return acc
  }, {})

  const letterGroupsKeys = Object.keys(letterGroups)

  letterGroupsKeys.forEach(key => {
    letterGroups[key].sort((a, b) => {
      a = a.toLowerCase()
      b = b.toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
  })

  const letterGroupsSorted = sortObjectKeys(letterGroups)

  return {
    breadcrumbs,
    count,
    data: letterGroupsSorted,
    description,
    meta,
    title,
  }
}

export default KnowledgeCenterPageTemplate
